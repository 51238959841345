import { Exclaim } from "easyship-components/icons";
import React from "react";

export interface LayoutErrorProps {
  title: string;
  children: React.ReactNode;
}

export const LayoutError = ({
  title,
  children,
}: LayoutErrorProps): React.ReactElement => (
  <div className="flex flex-col items-center justify-center h-screen text-ink-900">
    <div className="text-[72px]">
      <Exclaim className="w-20 h-20 mb-4 text-red-500" />
    </div>
    <h4 className="mb-3 text-3xl font-bold">{title}</h4>
    {children}
  </div>
);
