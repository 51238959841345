import { z } from "zod";

export const formSchema = z.object({
  nickname: z.string().min(1, ''),
  accountNumber: z.string().min(1, ''),
  customerName: z.string().min(1, ''),
  name: z.string().min(1, ''),
  phoneNumber: z.string().min(1, ''),
  email: z.string().min(1, ''),
  countryCode: z.string().min(1, ''),
  postalCode: z.string().min(1, ''),
  city: z.string().min(1, ''),
  state: z.string().min(1, ''),
  line1: z.string().min(1, ''),
  line2: z.string().optional(),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
