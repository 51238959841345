import { FormValues, formSchema } from "./schema";
import { CourierFormComponentProps } from "../types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/Form";
import { ControlledInput } from "@/components/ControlledInput";

export const COMPATIBLE_UMBRELLA_NAMES = {
  canadaPost: "Canada Post",
} satisfies Record<string, string>;

export const FormComponent = ({
  onSuccess,
}: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form
      onSubmit={handleSubmit(onSuccess)}
      hasValidationErrors={Object.keys(errors).length !== 0}
    >
      <ControlledInput
        name="nickname"
        control={control}
        label="Account Name"
        placeholder="e.g. MyCarrierAccount 01"
        statusText="The account name will be used to identify your account"
      />

      <ControlledInput
        name="merchantNumber"
        control={control}
        label="Merchant Number"
      />
    </Form>
  );
};
