import { z } from "zod";

export const formSchema = z.object({
  accountNumber: z.string().min(1, ''),
  nickname: z.string().min(1, ''),
  customerName: z.string().min(1, ''),
  obaEmailAddress: z.string().min(1, ''),
  intersoftPostingLocation: z
    .string()
    .length(10, '')
    .startsWith('9', ''),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
