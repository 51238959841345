import { z } from "zod";

export const formSchema = z.object({
  nickname: z.string().min(1, ''),
  accountNumber: z.string().min(1, ''),
  password: z.string().min(1, ''),
  userId: z.string().min(1, ''),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
