import { z } from 'zod';

export const formSchema = z.object({
  accountName: z.string().min(1, ''),
  username: z.string().min(1, ''),
  password: z.string().min(1, ''),
  originCode: z.string().min(1, ''),
  senderCode: z.string().min(1, ''),
});

export type FormValues = z.infer<typeof formSchema>;
