import React from "react";
import { FormValues, formSchema } from "./schema";
import { CourierFormComponentProps } from "../types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/Form";
import { ControlledInput } from "@/components/ControlledInput";
import { Separator } from "@/components/Separator";
import { Alert } from "easyship-components";

export const COMPATIBLE_UMBRELLA_NAMES = {
  dhl: "DHL",
  dhlImport: "DHL Import",
  dhlECommerce: "DHL eCommerce",
  dhlECommerceMetro: "DHL eCommerce Metro",
} satisfies Record<string, string>;

export const FormComponent = ({
  onSuccess,
}: CourierFormComponentProps<FormValues>): React.ReactElement => {
  const { control, handleSubmit, formState: { errors }  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <>
      <p>
        By connecting your DHL account, you will no longer be eligible to use
        the discounted DHL rates offered by Easyship.
      </p>
      <Separator />
      <Alert className="mb-4">
        Be sure to use your MyDHL API Credentials. Do not insert any spaces to avoid inputting invalid credentials.
      </Alert>
      <Form
        onSubmit={handleSubmit(onSuccess)}
        hasValidationErrors={Object.keys(errors).length !== 0}
      >
        <ControlledInput
          name="nickname"
          control={control}
          label="Account Name"
          placeholder="e.g. MyCarrierAccount 01"
          statusText="The account name will be used to identify your account"
        />

        <ControlledInput
          name="accountNumber"
          control={control}
          label="DHL Account No."
          placeholder="e.g. 123456789"
          statusText="digit Export Account ID accessible from MyDHL"
        />

        <ControlledInput
          name="apiKey"
          control={control}
          label="MyDHL API Key"
        />

        <ControlledInput
          name="apiSecret"
          control={control}
          label="MyDHL API Secret"
          statusText="16-character key on the DHL Developer Portal"
          type="password"
        />
      </Form>
    </>
  );
};
