import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { CurrentUser } from "@/models/User";
import { CountryResponse } from "@/models/Address";
import axios from "axios";
import { useAuthContext } from "@/providers/AuthProvider";

/**
 * Returns current user data including company id
 */
export const useCurrentUser = (): UseQueryResult<CurrentUser> => {
  const { credentials } = useAuthContext();

  return useQuery<CurrentUser>({
    enabled: !!credentials,
    queryKey: ["current-user"],
    queryFn: async (): Promise<CurrentUser> => {
      const { data } = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/users/get_current_user`
      );
      // Temporary filter - FedEx Cross Border & APG forms are not ready yet
      const hidden = ["fedex-cross-border", "apg"];
      return {
        shippingCountries: data?.company.shipping_countries.map(
          (country: CountryResponse) => ({
            name: country.name,
            alpha2: country.alpha2,
            availableCouriers: Object.entries(country.features.lyoc.couriers)
              .filter(([, { visible, contact_sales }]) => visible && !contact_sales)
              .sort()
              .map(([key]) => key)
              .filter((key) => !hidden.includes(key)),
          })
        ),
        companyId: data?.company.id,
      };
    },
    staleTime: Infinity,
  });
};
